<template>
  <div class="page">
    <div class="page__header row justify-content-center">
      <div class="col-12 col-xs-11 page__header__content">
        <h1 class="page__header__title">{{ $t('navSideForm') }}</h1>
        <!--
        <form id="search"><search-input-gcr name="query" v-model="searchQuery" :placeholder="$t('projectSearch')" /></form>
        -->
      </div>
    </div>
    <div class="page__body row justify-content-center">
      <div class="col-12 col-xs-11 page__body__col">
        <h2>{{ $t('formListTitleHouse') }}</h2>
        <h3>{{ $t('formListSubtitleRegister') }}</h3>
        <p>{{ $t('formListIntroHouse') }}</p>
        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/enregistrement-maison')">
              <td class="valign-center">
                Formulaire d'enregistrement - Bâtiment unifamilial ou multifamilial (non détenu en copropriété divise)
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/liberation-garantie-24mois-suivants')">
              <td class="valign-center">
                Libération de garantie 24 mois suivants la fin des travaux
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/annulation-enregistrement-maison')">
              <td class="valign-center">
                Formulaire d’annulation d’enregistrement
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h3>{{ $t('formListSubtitleBuyerContract') }}</h3>
        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/contrat-garantie-maison')">
              <td class="valign-center">
                Contrat de garantie - Bâtiment non détenu en copropriété divise
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/contrat-preliminaire')">
              <td class="valign-center">
                Contrat préliminaire - Maison ou bâtiment (Immeuble) non détenu en copropriété divise
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/contrat-entreprise-maison')">
              <td class="valign-center">
                Contrat d'entreprise
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/attestation-accompte')">
              <td class="valign-center">
                Attestation d'acompte
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/annexes-contrat-preliminaire-inclusion-exclusion-modifications')">
              <td class="valign-center">
                Annexes au contrat préliminaire et au contrat d'entreprise - Inclusions/Exclusions/Modifications
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/annexes-contrat-preliminaire-autres-conditions')">
              <td class="valign-center">
                Annexes au contrat préliminaire et au contrat d'entreprise - Autres conditions
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/inspection-prereception-maison')">
              <td class="valign-center">
                Formulaire d'inspection préréception - Bâtiment non détenu en copropriété divise
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h3>{{ $t('formListSubtitleBuilding') }}</h3>
        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/avis-fin-travaux-maison')">
              <td class="valign-center">
                Avis de fin des travaux - Bâtiment non détenu en copropriété divise
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/declaration-execution-finale-travaux')">
              <td class="valign-center">
                Déclaration d'exécution finale des travaux
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h2>{{ $t('formListTitleCondo') }}</h2>
        <h3>{{ $t('formListSubtitleRegister') }}</h3>

        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/enregistrement-condos')">
              <td class="valign-center">
                Formulaire d'enregistrement - Bâtiments détenus en copropriété divise
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/annulation-enregistrement-maison')">
              <td class="valign-center">
                Formulaire d’annulation d’enregistrement
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h3>{{ $t('formListSubtitleBuyersPrivateParties') }}</h3>

        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/contrat-garantie-condo')">
              <td class="valign-center">
                Contrat de garantie - Bâtiment détenu en copropriété divise
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/contrat-preliminaire-condos')">
              <td class="valign-center">
                Contrat préliminaire - Copropriété divise résidentielle
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/annexes-contrat-preliminaire-stationnement')">
              <td class="valign-center">
                Annexes au Contrat préliminaire - Copropriété divise résidentielle - Espace(s) de stationnement et case(s) de
                rangement <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/annexes-contrat-preliminaire-coordonnees-generales-copropriete')">
              <td class="valign-center">
                Annexes au Contrat préliminaire - Copropriété divise résidentielle - Coordonnées générales de la copropriété
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/annexes-contrat-preliminaire-inclusion-exclusion-modifications')">
              <td class="valign-center">
                Annexes au Contrat préliminaire - Copropriété divise résidentielle - Inclusions/Exclusions/Modifications
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/contrats/annexes-contrat-preliminaire-autres-conditions')">
              <td class="valign-center">
                Annexes au Contrat préliminaire - Copropriété divise résidentielle - Autres conditions
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/attestation-accompte')">
              <td class="valign-center">
                Attestation d'acompte
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/inspection-prereception-maison')">
              <td class="valign-center">
                Formulaire d'inspection préréception - Partie privative
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/avis-fin-travaux-maison')">
              <td class="valign-center">
                Avis de fin des travaux - Partie privative
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h3>{{ $t('formListSubtitleBuildingCommonAreas') }}</h3>
        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/inspection-prereception-condo-parties-communes')">
              <td class="valign-center">
                Formulaire d'inspection préréception - Parties communes
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/avis-fin-travaux-parties-communes')">
              <td class="valign-center">
                Avis de fin des travaux - Parties communes
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/fiche-batiment-declaration-conformite')">
              <td class="valign-center">
                Fiche-bâtiment / Déclaration de conformité
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/declaration-execution-finale-travaux')">
              <td class="valign-center">
                Déclaration d'exécution finale des travaux
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <h2>{{ $t('formListTitlePaperFormOrders') }}</h2>

        <table class="table table-bordered table-filter table-hover v-formlist__table">
          <tbody>
            <tr v-on:click="openLink('https://www.garantiegcr.com/portail/formulaires/articles-promos')">
              <td class="valign-center">
                Bon de commande - Articles promotionnels
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
            <tr v-on:click="openLink('https://www.garantiegcr.com/app/uploads/2019/04/gcr-bon-commande.pdf')">
              <td class="valign-center">
                Bon de commande - Formulaires papier
                <span v-if="isEnglish" class="v-formlist__language">({{ $t('oppositeLanguageTranslation') }})</span>
              </td>
              <td class="valign-center" width="60"><button class="btn btn-download" v-tooltip="this.$t('download')"></button></td>
            </tr>
          </tbody>
        </table>

        <div class="row v-formlist__images">
          <div class="col-6 col-xs-6 col-sm-4">
            <img src="../assets/gcr-affichette-de-chantier.jpg" width="500" height="500" class="v-formlist__images-image" />
            <h3 class="v-formlist__images-title">{{ $t('formListConstructionSitePosterSign') }}</h3>
            <p class="v-formlist__images-text">{{ $t('formListConstructionSitePosterSignSize') }}</p>
          </div>
          <div class="col-6 col-xs-6 col-sm-4">
            <img src="../assets/gcr-autocollant-vf.jpg" width="500" height="500" class="v-formlist__images-image" />
            <h3 class="v-formlist__images-title">{{ $t('formListSticker') }}</h3>
            <p class="v-formlist__images-text">{{ $t('formListStickerSize') }}</p>
          </div>
          <div class="col-6 col-xs-6 col-sm-4">
            <img src="../assets/gcr-drapeaux-petit-format.jpg" width="500" height="500" class="v-formlist__images-image" />
            <h3 class="v-formlist__images-title">{{ $t('formListFlag') }}</h3>
            <p class="v-formlist__images-text">{{ $t('formListFlagSize') }}</p>
          </div>
        </div>

        <!-- // -->
      </div>
    </div>
  </div>
</template>

<script>
import activeAccreditation from '@/components/mixins/active-accreditation'
import i18n from '@/i18n'

require('../utilities/page')
require('../utilities/table')
require('../utilities/button')

export default {
  components: {},
  mixins: [activeAccreditation],
  methods: {
    openLink: function(link) {
      if (link.startsWith('/')) {
        this.$router.push(link)
      } else {
        //window.location.href = link
        window.open(link)
      }
    }
  },
  computed: {
    isEnglish() {
      return i18n.locale === 'en'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../utilities/config';
.page__header__title {
  &::after {
    background-image: none;
  }
}

.v-formlist {
  &__table {
    margin: 15px 0;
    tr {
      background-color: gray('white');
    }
  }
  &__language {
    font-style: italic;
  }
  &__images {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
    &-image {
      border: 1px solid gray('gray-150');
      max-width: 100%;
      height: auto;
    }
    &-title {
      margin-top: 15px;
      margin-bottom: 0;
    }
    &-text {
      font-size: 14px;
    }
  }
}
</style>
